import React, { Component, Suspense } from "react";

import { initializeApp } from "firebase/app"
//import { getPerformance } from "firebase/performance";
import { getAnalytics } from "firebase/analytics";

import withStyles from '@mui/styles/withStyles';

import random from 'random-esm';
import { connect } from "react-redux";

import Background from "./components/Background";
import "./App.css";

const InfoDialog = React.lazy(() => import("./components/InfoDialog"));
const SpinningWheel = React.lazy(() => import("./components/RandomSelectionWheel"));

const Button = React.lazy(() => import("@mui/material/Button"));
const Dialog = React.lazy(() => import("@mui/material/Dialog"));
const DialogActions = React.lazy(() => import("@mui/material/DialogActions"));
const DialogContent = React.lazy(() => import("@mui/material/DialogContent"));
const SvgIcon = React.lazy(() => import("@mui/material/SvgIcon"));

const CountUp = React.lazy(() => import("react-countup"));
const Confetti = React.lazy(() => import("react-confetti"));


let firebaseLogEvent;
let store = window.localStorage;

const firebaseConfig = {
  apiKey: "AIzaSyBb2JLoPdIxxRDJcX1cXZWtN8veJeUPGVk",
  authDomain: "math-spinwheel.firebaseapp.com",
  databaseURL: "https://math-spinwheel.firebaseio.com",
  projectId: "math-spinwheel",
  storageBucket: "math-spinwheel.appspot.com",
  messagingSenderId: "719880866067",
  appId: "1:719880866067:web:5948f55b5a6eda48b472e6",
  measurementId: "G-T4ZNJPQQJ2"
};
const app = initializeApp(firebaseConfig);
//const perf = getPerformance(app);
const analytics = getAnalytics(app);

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      infoDialogOpen: false,
      resultCount: 0,
      totalWon: 0,
      score: 5,
      wheelOptions: {
        1: {
          image: require("./imgs/small.webp"),
          result: "./imgs/small.webp",
        },
        2: {
          image: require("./imgs/med.webp"),
          result: "./imgs/med.webp",
        },
        3: {
          image: require("./imgs/large.webp"),
          result: "./imgs/large.webp",
        },
        4: {
          image: require("./imgs/small.webp"),
          result: "./imgs/small.webp",
        },
        5: {
          image: require("./imgs/med.webp"),
          result: "./imgs/med.webp",
        },
        6: {
          image: require("./imgs/small.webp"),
          result: "./imgs/small.webp",
        },
      },
      seedScores: {
        small: 2,
        med: 4,
        big: 8
      },
      runConfetti: false,
      height: "500px",
      width: "500px",
      user: {},
      uid: null
    };

    this.domRef = React.createRef();
  }

  get = (key = null) => {
    try {
      return JSON.parse(store.getItem(key));
    } catch (error) {
      return null;
    }
  }

  set = (key = 'foo', value = {}) => {
    store.setItem(key, JSON.stringify(value));
  }

  UNSAFE_componentWillMount() {
    this.setState({
      height: window.innerHeight + "px",
      width: window.innerWidth + "px",
    });
  }

  componentDidMount() {
    this.logIn();
    this.seedScores()

    setTimeout(() => {
      import('firebase/analytics')
        .then(({ setUserProperties, logEvent }) => {
          setUserProperties(analytics, { favorite_food: 'apples', appVersion: `${process.env.REACT_APP_VERSION}` });
          firebaseLogEvent = logEvent
        });
    }, 100);
  }

  logIn = async () => {
    const { get, set } = this;
    let uid = get('uid'), user = get('user');

    if ((uid === null) || (user === null)) {
      const { signInAnonymously, indexedDBLocalPersistence, initializeAuth } = await import('./utils/auth');

      const auth = initializeAuth(app, { persistence: [indexedDBLocalPersistence] });

      const e = await signInAnonymously(auth);

      uid = e.user.uid;
      user = e.user;

      set('user', user);
      set('uid', uid);
    }

    this.setState({ user, uid }, () => {
      console.log('logging in...')
      this.logUser(user)
    })
  }


  log = async (data, coll = "stats") => {
    const { getFirestore, addDoc, collection, Timestamp } = await import('./utils/firestore')

    const db = getFirestore(app);
    const { uid } = this.state;

    const docRef = await addDoc(collection(db, coll), { ...data, createdAt: Timestamp.now(), user: uid });
    return docRef
  }

  fingerprint = async () => {
    const { get, set } = this;
    let me = get('device');

    if (me === null) {
      const DeviceDetector = new (await require('device-detector-js'))()
      let device = DeviceDetector.parse(navigator.userAgent)

      me = device;

      set('device', device)
    }

    return { device: me };
  }

  logUser = async (user) => {
    const { uid } = user;
    const { getFirestore, setDoc, doc, Timestamp } = await import('./utils/firestore');

    const db = getFirestore(app);

    const { device } = await this.fingerprint();

    await setDoc(doc(db, "users", uid), {
      device: device,
      accessedUrl: window.location.hostname,
      appVersion: `${process.env.REACT_APP_VERSION}`,
      updatedAt: Timestamp.now()
    }, { merge: true });
  }

  seedScores = async () => {
    const seedScores = {
      small: random.int(1, 3),
      med: random.int(3, 6),
      big: random.int(7, 10)
    }

    this.setState({ seedScores })
  }

  handleClickOpen = () => {
    const { currentWon } = this.state;

    this.log({
      score: currentWon
    }, 'scores')

    this.setState({ open: true }, () => {
      setTimeout(() => {
        firebaseLogEvent(analytics, 'spin_won', {
          score: String(currentWon)
        });
      }, 200);
    });
  };

  handleClose = () => {
    window.location.reload(false);
  };

  calcTotalWon = (e) => {
    const { small, med, big } = this.state.seedScores;
    let retuner = 0;


    if (e.includes("med") && e.includes('webp')) {
      console.log('(3-6)')
      retuner = med;
    } else if (e.includes("small") && e.includes('webp')) {
      console.log('(1-3)')
      retuner = small;
    } else {
      console.log('(7-10)')
      retuner = big;
    }

    this.setState({ currentWon: retuner });

    return retuner;
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.todos?.length > 0 &&
      this.props.todos?.length !== prevProps.todos?.length
    ) {
      const totalWon = this.calcTotalWon(
        this.props.todos[this.props.todos.length - 1]?.text

      )
      this.setState(
        {
          totalWon: totalWon,
          runConfetti: true,
        }, () => { this.handleClickOpen() })




    }
  }


  fontIconClick = () => {
    this.log({ clicked: "github" })
    window.open('https://github.com/connlark/math-spinwheel')
  }


  handleInfoDialogOpen = (open = true) => {
    this.setState({ infoDialogOpen: open })
  }

  render() {
    const { wheelOptions } = this.state;


    return <>
      <Background />
      <div>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
          <div style={{ padding: "20px", margin: "20px" }}>
            <div onClick={this.fontIconClick} style={{ opacity: 1, zIndex: 1000, transform: 'translate(1%)', position: "fixed", left: '1%', top: '1%' }}>
              <SvgIcon color='primary' htmlcolor={'#181717'} viewBox='0 0 24 24' style={{ zIndex: 1000, color: '#181717' }} >
                <path d="M12 .297c-6.63 0-12 5.373-12 12 0 5.303 3.438 9.8 8.205 11.385.6.113.82-.258.82-.577 0-.285-.01-1.04-.015-2.04-3.338.724-4.042-1.61-4.042-1.61C4.422 18.07 3.633 17.7 3.633 17.7c-1.087-.744.084-.729.084-.729 1.205.084 1.838 1.236 1.838 1.236 1.07 1.835 2.809 1.305 3.495.998.108-.776.417-1.305.76-1.605-2.665-.3-5.466-1.332-5.466-5.93 0-1.31.465-2.38 1.235-3.22-.135-.303-.54-1.523.105-3.176 0 0 1.005-.322 3.3 1.23.96-.267 1.98-.399 3-.405 1.02.006 2.04.138 3 .405 2.28-1.552 3.285-1.23 3.285-1.23.645 1.653.24 2.873.12 3.176.765.84 1.23 1.91 1.23 3.22 0 4.61-2.805 5.625-5.475 5.92.42.36.81 1.096.81 2.22 0 1.606-.015 2.896-.015 3.286 0 .315.21.69.825.57C20.565 22.092 24 17.592 24 12.297c0-6.627-5.373-12-12-12" />
              </SvgIcon>
            </div>

            <h3 onClick={this.handleInfoDialogOpen} style={{
              overflow: 'hidden',
              zIndex: 1000, position: "fixed", bottom: '-17px', right: '3px', fontFamily: 'courier', textAlign: 'center'
            }}>
              math-spinwheel.web.app
            </h3>


            <SpinningWheel
              sources={wheelOptions}
              numberOfSources={6}
              durationOfSpin={3}
              backgroundColor="black"
              outerRingColor="black"
              buttonColor="transparent"
            />
          </div>
        </div>
      </div >

      <Suspense fallback={<div>Loading...</div>}>


        <InfoDialog
          infoDialogOpen={this.state.infoDialogOpen}
          handleInfoDialogOpen={this.handleInfoDialogOpen}
        />


        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>

            <CountUp
              end={this.state.totalWon}
              duration={this.state.totalWon < 5 ? 0.01 : 2}
              decimals={0}
              className="App-textwon"
            />

          </DialogContent>

          <DialogActions>
            <Button
              onClick={this.handleClose}
              variant="outlined"
              //autoFocus
              style={{ zIndex: 100 }}>
              Spin Again!
            </Button>
          </DialogActions>
        </Dialog>

        <div className="confetti">
          <Confetti
            run={this.state.open}
            width={this.state.width}
            height={this.state.height}
          />
        </div>

      </Suspense>
    </>;
  }
}
const styles = {
  dialogPaper: {
    maxHeight: "40vh",
    maxWidth: "40vh",
  },
};

const mapStateToProps = (state) => ({
  todos: state.todos,
});

const mapDispatchToProps = (dispatch) => ({
  toggleTodo: (id) => null,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(App));
