import { lazy, Suspense } from 'react';
import { useLocalStorage } from '../../utils/useLocalStorage';

const MobileView = lazy(() => import('./mobile'));
const DesktopView = lazy(() => import('./desktop'));

function Background() {
    const [mobile, setMobile] = useLocalStorage("mobile", true);

    setTimeout(async () => {
        const { isMobile } = await import('react-device-detect');
        setMobile(isMobile)


        if (isMobile !== mobile) {
            setMobile(isMobile)
        }
        console.log(isMobile)
    }, 550);

    if (mobile) {
        return (
            <MobileView />
        )
    }

    return (

        <Suspense fallback={<MobileView />}>
            <DesktopView />
        </Suspense>

    )
}

export default Background;
