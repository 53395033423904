import * as ReactDOMClient from 'react-dom/client';
import { Provider } from "react-redux";
import { createStore } from "redux";

import "./index.css";
import rootReducer from "./reducers";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";


Object.values = (obj) => Object.keys(obj).map((key) => obj[key]);

function handleFirstTab(e) {
  if (e.keyCode === 9) {
    document.body.classList.add("user-is-tabbing");
    window.removeEventListener("keydown", handleFirstTab);
  }
}

console.log(`version: ${process.env.REACT_APP_VERSION}`);

window.addEventListener("keydown", handleFirstTab);

const store = createStore(
  rootReducer,
  {},
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

const onServiceWorkerUpdate = (registration) => {
  console.log("updating... registration:");

  registration?.waiting?.postMessage({ type: "SKIP_WAITING" });
};

serviceWorkerRegistration.register({
  onUpdate: onServiceWorkerUpdate,
});

const container = document.getElementById('app');
const root = ReactDOMClient.createRoot(container);

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
